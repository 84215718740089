/**
 * Класс для работы с событиями
 */
export default class EventEmitter {
	/**
	 * Инициализировать EventEmitter
	 */
	constructor() {
		this.events = []
	}

	/**
	 * Подписаться на событие
	 * @param {String} name имя события
	 * @param {Function} handler обработчик события
	 * @return {void}
	 */
	on(name, handler) {
		this.events.push({ name, handler })
	}

	/**
	 * Отписаться от события
	 * @param {String} name имя события
	 * @param {Function|null} handler обработчик события
	 * @return {void}
	 */
	off(name, handler = null) {
		if (handler) {
			this.events = this.events.filter(event => event.name !== name || event.handler !== handler)
		} else {
			this.events = this.events.filter(event => event.name !== name)
		}
	}

	/**
	 * Вызвать событие
	 * @param {String} name имя события
	 * @param {*} params параметры для обработчика
	 * @return {void}
	 */
	emit(name, ...params) {
		this.events
			// Найти подходящие события
			.filter(event => event.name === name)

			// Вызвать все подходящие события
			.forEach(event => {
				if (typeof event.handler === "function") {
					event.handler(...params)
				} else {
					throw new Error(`Обработчик события "${event.name}", не является функцией`)
				}
			})
	}
}
