<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
import LocalStorage from "@/utils/localStorage"
import EventBus from "@/utils/EventBus"

export default {
	name: "AppComponent",

	created() {
		const token = LocalStorage.get("token", null)

		if (!token) {
			this.$router.replace({ name: "Login" })
		}

		EventBus.on("api:request-get-401", () => {
			this.$router.replace({ name: "Login" })
		})
	}
}
</script>

<style lang="scss">
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}

#nav {
	padding: 30px;

	a {
		font-weight: bold;
		color: #2c3e50;

		&.router-link-exact-active {
			color: #42b983;
		}
	}
}
</style>
