/**
 * Локальное хранилище
 */
export default {
	/**
	 * Получить данные из хранилища
	 * @param {String} key
	 * @param {*} defaultValue
	 */
	get(key, defaultValue = null) {
		const value = JSON.parse(localStorage.getItem(key))

		return value !== null ? value : defaultValue
	},

	/**
	 * Установить данные в хранилище
	 * @param {String} key
	 * @param {Object|String|Number} value
	 */
	set(key, value) {
		localStorage.setItem(key, JSON.stringify(value))
	}
}
